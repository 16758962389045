var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'Browse' }}},[_vm._v(" Browse ")])],1),_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'Examination', query: { examId: _vm.examId } }}},[_vm._v(" "+_vm._s(_vm.testName)+" ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.sectionName)+" ")])],1)],2),_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),_c('router-link',{attrs:{"to":{ name: 'CreatePassage' }}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('el-table',{staticClass:"passages",attrs:{"width":"100%","data":_vm.passages}},[_c('el-table-column',{attrs:{"label":"Content","width":"800px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{domProps:{"innerHTML":_vm._s(scope.row.content.slice(0, 100))}}),_c('router-link',{attrs:{"to":{
              name: 'Passage',
              query: { passageId: scope.row.id, examId: _vm.examId }
            }}},[_vm._v(" "+_vm._s(`${scope.row.content.length > 100 ? "More..." : ""}`)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Question Count"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'Passage',
              query: { passageId: scope.row.id, examId: _vm.examId }
            }}},[_vm._v(" "+_vm._s(scope.row.question_count)+" ")])]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"Action","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'Passage',
              query: { passageId: scope.row.id, examId: _vm.examId }
            }}},[_c('i',{staticClass:"fa fa-eye action-icon"})]),_c('router-link',{attrs:{"to":{
              name: 'EditPassage',
              query: { passageId: scope.row.id, examId: _vm.examId }
            }}},[_c('i',{staticClass:"fa fa-edit"})]),_c('span',{on:{"click":() => _vm.deletePassage(scope.row.id)}},[_c('i',{staticClass:"fas fa-trash-alt warning-icon"})])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }